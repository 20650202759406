/* general */

.form_container {
    text-align: center;
    align-items: center;
    display: inline-block;
    padding-bottom: 10%;

    
}

.btn{
    display:flex;
    height: auto;
    width: auto;
    justify-content:center;
    align-items: center;
    text-align: center;
    position:absolute;
    bottom:10px;
    left:25%
}

.progress_btn{
    display:inline-block;
    height: auto;
    width: auto;
    justify-content:center;
    align-items: center;
    text-align: center;
    position:absolute;
    bottom:10px;
    padding-left: 1%;
}

.progress_button {
    color: #EFEFEF;
    border: 1px solid #EFEFEF;
    border-radius: 10px;
    width: 93px;
    height: 27px;
    background: #8b0000;
    align-items: center;
    font-size: medium;
    position: relative;
    bottom: 0;
    padding-top: 5px;
}

.sections_container {
    display: flex;
    /* flex-flow: column;
    align-items: center; */
    max-width: 1120px;
    width: 50%;
    min-width: 800px;
    margin: auto;
    position: relative;
    text-align: center;
    height:auto;

    /* Inside auto layout */
    
    /* flex: none;
    order: 0;
    flex-grow: 0; */
}

.form {
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 10px;
    width: 581px;
    height: auto;
    min-height: 454px;
    display: inline-block;
    vertical-align: top;
    margin-right: 10px;
}


.progress_bar {
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 10px;
    width: 205px;
    
    display: inline-block;
    vertical-align: top;

}

.progress_bar > ul {
    list-style: none;
    text-align: left;
    padding-left: 0%
}

.progress_bar_list > h2 {
    font-size: large;
    text-align: left;
    padding-left: 10px;
    font-family: Arial, Helvetica, sans-serif;
    list-style: none;
    text-decoration: underline;
}

.medium_text_side, .entry_header_side {
    font-size: medium;
    text-align: left;
    padding-left: 10px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
}

.entry_header {
    font-size: small;
    font-family: Arial, Helvetica, sans-serif;
}

.medium_text_center {
    font-size: small;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
}

.input_header {
    font-size: large;
    font-family: Arial, Helvetica, sans-serif;
}

.title {
    font-size: x-large;
    font-family: Arial, Helvetica, sans-serif;
}

/* upload */

.upload_container {
    display: flex;
    text-align: center;
    width: auto;
    margin: auto;
    position: relative;
    align-items: center;
    justify-content: center;
}

.upload_box {
    width: 188px;
    height: 99px;
    align-items: center;
    justify-content: center;
    margin: 5px;
    background: #bd8080;
    border-radius: 10px;
    display: flex;
}

/* prior experience */
.input_container {
    display:block;
    width:auto;

}

.input_container > input {
    width: 50%;
    padding:12px 20px ;
    margin: 8px 0;

}

.input_box {
    width: 80%;
    padding:12px 20px ;
    margin: 8px 0;
    padding-right: 15px;
    border-radius: 10px;
    border:none

}

.header {
    display:flex; 
    /* flex-direction:row; */
    width:auto;
    justify-content: start;
    align-items: center;
}
/*
.add_job_experience {
    margin-bottom: 0;
    display: inline-block;
    vertical-align: bottom;
}

.input_header{
    display: inline-block;
    vertical-align: top;
} */

.experience {
    display:flex; 
    /* flex-direction:row; */
    width:auto;
    justify-content: center;
    align-items: center;
}

.remove {
    width: 70px;
    height: 20px;
    color: #EFEFEF;
    border: 1px solid #EFEFEF;
    border-radius: 10px;
    background: #8b0000;
    align-items: center;
    font-size: small;
}

.add {
    width: 45px;
    height: 20px;
    color: #EFEFEF;
    border: 1px solid #EFEFEF;
    border-radius: 10px;
    background: #8b0000;
    align-items: center;
    font-size: small;
}

.input_container{
    background-color: #f5e1dd;
    width:500px;
    position:relative;
    border-radius: 10px;
}

.prev_experience {
    display: inline-block;
}

.info_input {
    background-color: #ffffff;
    width:300px;
    height: 30px;
    /* position:relative; */
    border-radius: 10px;
    border: none;
}

.single_input {
    display: inline-block;
    /* margin: px; */
    padding-bottom: 10px;
}

.entry_header {
    display: flex;
    justify-content: start;
}