.mainAssociationBox {
    position: absolute;
    width: 330px;
    left: 72%;
    top: 94px;

    background: #FFFFFF;
    border-radius: 10px;
}

.otherPeopleLine {
    text-align: center;
    margin-top: 10px;
    font-size: 20px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 700;
}

.employerLine {
    text-align: center;
    margin-top: 10px;
    font-size: 20px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 700;
}

.associationBoxPink {
    width: 300px;

    background: #e3d1d1;
    border-radius: 5px;
    margin: auto;
    padding-top: 1px;
    padding-bottom: 1px;
    margin-bottom: 20px;
}

.mainProfileBox {
    position: absolute;
    width: 830px;
    left: 2%;
    top: 94px;

    background: #FFFFFF;
    border-radius: 10px;
    padding-bottom: 30px;
}

.otherProfilePicture {
    position: absolute;
    width: 130px;
    height: 130px;
    top: 20px;
    left: 80px;
    border-radius: 50%;
}

.otherDescription {
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    width: 40%;
    background: #e3d1d1;
    box-shadow: 0px 4px 4px rgba(71, 23, 23, 0.25);
    border-radius: 10px;
}

.otherHeader {
    text-align: center;
    color: #8b0000;
    font-size: 28px;
    padding: 10px;
    margin: 8px;
}

.otherJobDes {
    color: #8b0000;
    font-size: 18px;
    padding: 0px;
    margin: 0;
}

.otherExperienceBoxes {
    background-color: #e3d1d1;
    margin: auto;
    margin-top: auto;
    padding: 20px;
    width: 75%;
    height: 30%;
    border-radius: 10px;
}

.otherTitle {
    color: #8b0000;
    margin-left: 25px;
    margin-top: 15px;
    width: 300px;
    font-size: 20px;
    display: inline-block;
}

.otherConnectButton {
    position: absolute;
    width: 93px;
    height: 27px;
    left: 650px;
    top: 78px;
  
    background: #8B0000;
    border: 1px solid #EFEFEF;
    border-radius: 10px;
  
    color: #EFEFEF;
}
