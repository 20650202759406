.box{
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    width: 1000px;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
  }
  
  .profilePicture{
    margin-top: 10%;
    margin-bottom:-9%;
    margin-left: 45%;
    width: 150px;
    height: 150px;
    
    border-radius: 50%;
  }
  
  .edit{
    margin-left: 96%;
    margin-top: 1.5%;
    width: 25px;
    height: 25px;
    background-size: cover;
    border: none;
  
  }
  
  .edit:hover {
    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
  }
  
  .description{
    margin-left: 37%;
    margin-right: auto;
    margin-top: 50px;
    width: 30%;
    height: 90px;
    background: #e3d1d1;
    text-align: center;
    box-shadow: 0px 4px 4px rgba(71, 23, 23, 0.25);
    border-radius: 10px;
  }

  .header{
    text-align:center;
    margin-left: 23%;
    color: #8b0000;
    font-size: 28px;
    padding: 10px;
  }
  
  .jobDes{
    color: #8b0000;
    font-size:18px;
    padding: 0px;
    margin:0;
  }
  
  .experienceBoxes{
    background-color:#e3d1d1;
    margin: auto;
    margin-top: auto;
    padding: 20px;
    width: 75%;
    height:30%;
    border-radius: 10px;
  }
  
  .Title{
    color: #8b0000;
    margin-left: 25px;
    margin-top: 15px;
    width: 300px;
    font-size: 20px;
    display:inline-block;
  }