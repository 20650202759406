.titles{
    color: #8b0000;
    margin:20px;
    font-size: 18px;
    display: inline;
}

.experienceTitles{
    color: #8b0000;
    font-size: 22px;
    padding-top:10px;
    padding-left:60px;
    padding-bottom:1px;
}

.whiteBox{
    border: 3px solid #fff;
    background-color: #ffff;
    width:90%;
    padding: 20px;
    margin-left: 3%;
    margin-right:auto;
    margin-top: 100px;
    height: 2200px;
    border-radius: 10px;
}
    
.insideBox{
    width: 35%;
    height: 300px;
    float: left;
    padding: 30px;
    border: none;
    margin-left: 6%;
}

.insideExperienceBox{
    width: 350px;
    height: 300px;
    float: left;
    padding: 20px;
    border: none;
    margin-left: 27px;
}

.inputs{
    width:355px;
    height:30px;
    background-color: #e3d1d1;
    border:none;
    border-radius: 10px;
    margin: 20px;
}

.experienceInputs{
    width:350px;
    height:30px;
    background-color: #ffff;
    border:none;
    border-radius: 10px;
    margin: 20px;
}

.descriptionInputs{
    width:770px;
    height:30px;
    background-color: #ffff;
    border:none;
    border-radius: 10px;
    margin: 20px;
}

.jobExperienceBox{
    position:absolute;
    background-color: #e3d1d1;
    width: 70%;
    height: 500px;
    margin: auto;
    top: 96%;
    left: 14.5%;
    border-radius:  10px;
}

.volunteerExperienceBox{
    position:absolute;
    background-color: #e3d1d1;
    width: 70%;
    height: 500px;
    margin: auto;
    top: 193%;
    left: 14.5%;
    border-radius:  10px;
}

.educationBox{
    position:absolute;
    background-color: #e3d1d1;
    width: 70%;
    margin: auto;
    top: 291%;
    left: 14.5%;
    border-radius:  10px;
}

.submitButton{
    position:absolute;
    background-color: #e3d1d1;
    border-radius: 10px;
    border:none;
    top: 375%;
    left: 37%;
    width: 300px;
    height: 50px;
}

