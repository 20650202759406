.profileBox {
    position: relative;
    width: 521px;
    height: 184px;
    left: 489px;
    top: 280px;
    margin-bottom: 20px;
    padding: 0.5%;
    
    background: #FFFFFF;
    border-radius: 10px;
}

.connect {
    position: absolute;
    width: 93px;
    height: 27px;
    left: 280px;
    top: 130px;
  
    background: #8B0000;
    border: 1px solid #EFEFEF;
    border-radius: 10px;
  
    color: #EFEFEF;
}

.connectLink {
    color: white;
    text-decoration: none;
}

.name {
    position: absolute;
    width: 350px;
    height: 184px;
    left: 150px;
    top: 20px;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    color: #000000;
}