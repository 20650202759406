.navBar {
    background: white;
    border: 1px solid #8b0000;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
    z-index: 999;
}

.navBar-box {
    display: flex;
    align-items: center;
    height: 60px;
    max-width: 100%;
}

.logo {
    color: #8b0000;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    
    position: absolute;
    width: 93px;
    height: 21px;
    left: 20px;
    top: 10px;
}

.navItems {
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 300vw;
    justify-content: end;
    margin-right: 2rem;
    margin-left: 600px;
}

.navIcons {
    display: grid;
    grid-template-columns: repeat(2, 30px);
    grid-gap: 5px;
    list-style: none;
    text-align: center;
    width: 60vw;
    justify-content: end;
    margin-right: 2%;
}

.pageItem {
    height: 15px;
}
  
.pageLink {
    color: #8b0000;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    line-height: 15px;
    text-align: center;
    text-decoration: none;
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    height: 5px;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}