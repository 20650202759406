.posting {
    position: relative;
    margin: auto;
    width: 800px;
    height: 184px;
    left: 0px;
    top: 250px;
    margin-bottom: 30px;
    padding: 0.5%;
}

.postingBox{
    position: absolute;
    width: 486px;
    height: 200px;
    left: 160px;
    top: 30px;
    font-family: Arial, Helvetica, sans-serif;
    background: #FFFFFF;
    border-radius: 10px;
    padding-top: 0.75%; 
    text-align: center;
    color: #000000
}

.postingDetail {
    position: absolute;
    width: 240px;
    height: 200px;
    left: 660px;
    top: 30px;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    color: #8B0000;
    padding-top: 0.75%;
    background: #FFFFFF;
    border-radius: 10px;
}

.more {
    position: absolute;
    width: 100px;
    height: 27px;
    left: 30%;
    top: 160px;
    background: #8B0000;
    border: 1px solid #EFEFEF;
    border-radius: 10px;
    color: #EFEFEF;
}

.saved {
    position: absolute;
    left: 90%;
    top: 15%;
    color: #8b0000;
}
  