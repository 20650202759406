.main {
    padding-top:6%;
    text-align: center;
}



.main > h1 {
    font-family: Arial, Helvetica, sans-serif;
    font-size: x-large;
    color: #480000;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.detailed_posting_container {
    display: flex;
    /* flex-flow: column;
    align-items: center; */
    max-width: 1120px;
    width: 50%;
    min-width: 800px;
    margin: auto;
    position: relative;
    text-align: center;
    height:auto;

    /* Inside auto layout */
    
    /* flex: none;
    order: 0;
    flex-grow: 0; */
}

.detailed_description_text > h1 {
    font-size: x-large;
    font-family: Arial, Helvetica, sans-serif;

}

.detailed_description_text > p {
    text-align: left;
    padding-left: 10px;
    padding-right: 10px;
    font-family: Arial, Helvetica, sans-serif;
    list-style: none;

}

.side_bar > h3 {
    font-size: x-small;
    text-align: left;
    padding-left: 10px;
    font-family: Arial, Helvetica, sans-serif;
}

.side_bar_list > h2 {

    font-size: medium;
    text-align: left;
    padding-left: 10px;
    font-family: Arial, Helvetica, sans-serif;
    list-style: none;
    text-decoration: underline;
}

.side_bar > ul {
    list-style: none;
    text-align: left;
    padding-left: 0%
}

.side_bar > h1 {
    font-size: x-large;
    font-family: Arial, Helvetica, sans-serif;

}

.side_bar_list > h3 {
    font-size: small;
    text-align: left;
    padding-left: 10px;
    font-family: Arial, Helvetica, sans-serif;
}

.side_bar_list {
    text-align: left;
    padding-left: 10px;
}

.detailed_description_text {
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 10px;
    width: 581px;
    height: auto;
    min-height: 454px;
    display: inline-block;
    vertical-align: top;
    margin-right: 10px;
}


.side_bar {
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 10px;
    width: 205px;
    
    display: inline-block;
    vertical-align: top;

}

.apply_button {
    color: #EFEFEF;
    margin-bottom: 10px;
    border: 1px solid #EFEFEF;
    border-radius: 10px;
    width: 93px;
    height: 27px;
    background: #8b0000;
    align-items: center;
    font-size: medium;
}

