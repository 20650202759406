.MainBox{
    margin-right:auto;
    margin-left:auto;
    margin-top: 100px;
    width: 500px;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    display: grid;
}

.LoginButtons{
    background: #e3d1d1;
    margin: auto;
    padding: 10px;
    width:400px;
    text-align:center;
    border:none;
    border-radius:10px;
    color:#8b0000;
}

.SignupButton{
    background: #e3d1d1;
    margin: auto;
    padding: 10px;
    width: 400px;
    text-align:center;
    border:none;
    border-radius:10px;
}

.EmailInput{
    background: #e3d1d1;
    margin: auto;
    border:none;
    border-radius:10px;
    width:400px;
}

.pageLink {
    color: #8b0000;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    line-height: 15px;
    text-align: center;
    text-decoration: none;
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    height: 5px;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.loginText{
    color: #8b0000;
}
